@import './variables';

:root {
  --big-control-size: 64px;
  --big-control-b-color: #e0e0e0;
  --big-control-b-radius: 8px;
  --cart-disabled-grey: #e0e0e0;
  --font-family-sans-serif: #{$font-family-sans-serif};
  --font-family-serif: #{$font-family-serif};
  /* RESTAURANT COLORS */
  --darkenPrimary: #f24516;
  --disabled: #{$disabled};
  --disabledPrimary: #f4623a;
  /* RESTAURANT COLORS END */
  --primary-transparent: #{$salmon};
  --primary-shadow: #f4623a80;
  --sidebar-border-radius: 16px;
}
