// Typography

.capitalize {
  text-transform: capitalize;
}
.text-white-75 {
  color: fade-out($white, 0.25);
}

.text-white-80 {
  color: fade-out($white, 0.2);
}

.underline {
  border-bottom: 1px solid var(--primary);
}

.underline-2 {
  border-bottom-width: 2px;
}

// Height & Width

.h-auto {
  height: auto !important;
}

.min-h-auto {
  min-height: auto !important;
}

.full-width {
  width: 100%;
}

.mw-200 {
  max-width: 200px;
}

.mw-300 {
  max-width: 300px;
}

.mw-400 {
  max-width: 400px;
}

.mw-500 {
  max-width: 500px;
}

.mw-550 {
  max-width: 550px;
}

.mw-600 {
  max-width: 600px;
}

.mw-700 {
  max-width: 700px;
}

.mw-750 {
  max-width: 750px;
}

// Other

.no-border { 
  border: 0;
}

.no-padding {
  padding: 0;
}

.text-primary {
  color: var(--primary) !important;
}

.xs-col-md-row {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  @include md {
    flex-flow: row wrap;
  }
}

.vertical-center {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.lh-1 {
  line-height: 1;
}

.clickable {
  cursor: pointer;
}

.centrify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate-180 {
  transform: rotate(180deg);
}
