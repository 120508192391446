// Variables
// Color overrides

// Set a custom color
$orange: #f4623a !default;
$brown: #5c4d42 !default;

// Set primary color to orange
$primary: $orange !default;
$secondary: white !default;
$disabled: #9E9E9E;
$danger: #dc3545;
$salmon: #fef2f0;
$sort-of-black: #352521;

$grey: #E0E0E0;

// Font overrides
$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$font-family-serif: "Zilla Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-family-base: $font-family-sans-serif !default;

$lato: 'Lato', sans-serif;
$zilla: 'Zilla Slab', serif;

$headings-font-family: $font-family-sans-serif !default;

// Custom variables
$navbar-height: 66px;
$navbar-height-md: 82px;
$navbar-link-color: $primary !default;

$cta-select-height: 50px;
$xl-font-size: 0.85rem;
$xl-font-size-md: 0.7rem;

$modal-md: 550px;

$z-index-navbar: 1030;
