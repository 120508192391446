// Global styling for this template
body,
html {
  width: 100%;
  height: 100%;
}

@import './utils';

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: var(--primary);
}

hr.light {
  border-color: $white;
}

.btn {
  font-family: $font-family-sans-serif;
}

.btn-xl {
  padding: 1rem 2rem;
  font-size: $xl-font-size;
  font-weight: 700;
  border-radius: 10rem;
  border: none;
  text-transform: uppercase;

  @include md {
    font-size: $xl-font-size-md;
    padding: 0.75rem 2rem;
  }
}

.page-section {
  padding: 4rem 0;

  @include md {
    padding: 8rem 0;
  }
}

.empty-d-none {
  &:empty {
    display: none;
  }
}

.bg-primary {
  &,
  a {
    @extend .text-white;
  }

  .divider {
    border-color: $secondary;
  }
}

.first-after-header {
  margin-top: $navbar-height + 10px !important;

  @include media-breakpoint-up(lg) {
    margin-top: $navbar-height-md + 10px !important;
  }
}

.header-spacer {
  background: transparent;
  display: block;
  width: 100%;
  height: $navbar-height;
  @include media-breakpoint-up(lg) {
    height: $navbar-height-md;
  }
}

.md-plus {
  display: none !important;

  @include md {
    display: flex !important;
  }
}

.md-minus {
  @include md {
    display: none !important;
  }
}

.until-md-fw {
  @include until-md {
    text-align: center;
    width: 100%;
  }
}

.fa-star-checked,
.fa-map-marker-alt,
.fa-utensils {
  color: var(--primary);
}
.order-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-status {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: 30px;
  color: var(--primary);
  font-weight: 400;
  animation: animate-pulse 1.4s linear infinite;
  min-height: 90px;
  @include md {
    min-height: 65px;
  }
}

@keyframes animate-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.star-ratings {
  unicode-bidi: bidi-override;
  color: #ccc;
  font-size: 20px;
  position: relative;
  margin: 0;
  padding: 0;
  .fill-ratings {
    color: var(--primary);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;

    // Allows us to grab the width of the span elements
    span {
      display: inline-block;
    }
  }
  .empty-ratings {
    padding: 0;
    display: block;
    z-index: 0;
  }
}

$badge-size: 30px;
.fab-btn {
  @extend .btn;
  @extend .btn-primary;
  background-color: var(--primary);
  border-color: var(--primary);
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  padding: 6px 12px;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, top 0.2s ease;
  &__badge {
    background: var(--secondary);
    border-radius: 50%;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
    height: $badge-size;
    width: $badge-size;
  }
  &--big {
    padding: 18px 20px;
    border-radius: 50%;
    svg {
      font-size: 20px;
    }
  }
  &--with-hover {
    &:hover {
      // important needed when overriding .btn-primary colors for integrated websites
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
  }
  &--with-shadow {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  }
}

.oto-tooltip {
  display: none;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  position: absolute;
  z-index: 3;
  top: -5px;
  transform: translateY(-100%);
  left: 0;
  padding: 7px 10px;
  min-width: 150px;
  max-width: 200px;

  @include md {
    min-width: 250px;
    max-width: 350px;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 20px;
    border-bottom: 0px;
    border-top: 5px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.modal-header .close {
  font-size: 2.75rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.modal-sm-fullscreen {
  @include until-md {
    margin: 0;
    .modal-content {
      border: 0px;
      border-radius: 0;
    }
    button[type='submit'] {
      text-align: center;
      width: 100%;
    }
  }
}

.oto-btn-inline {
  font-weight: 400;
  color: var(--primary);
  text-decoration: none;
  line-height: 1;
  padding: 0;
}

.brand-btn {
  background: var(--primary);
  background: -moz-linear-gradient(
    left,
    var(--primary) 0%,
    var(--darkenPrimary) 100%
  );
  background: -webkit-linear-gradient(
    left,
    var(--primary) 0%,
    var(--darkenPrimary) 100%
  );
  background: linear-gradient(
    to right,
    var(--primary) 0%,
    var(--darkenPrimary) 100%
  );
  color: var(--secondary);
  display: inline-block;
  padding: 11px 24px;
  font-size: 14px;
  border-radius: 50px;
  border: none;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.brand-btn:focus,
.brand-btn:active,
.brand-btn:hover {
  color: var(--secondary);
  background: var(--darkenPrimary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-image: -moz-linear-gradient(22deg, #f34f22 0%, #ee3e0d 100%);
  background-image: -webkit-linear-gradient(22deg, #f34f22 0%, #ee3e0d 100%);
  background-image: -ms-linear-gradient(22deg, #f34f22 0%, #ee3e0d 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12),
    0px 8px 8px 0px rgba(0, 0, 0, 0.12);
}
