$dish-margin-size: 15px;
$sidebar-min-width: 300px;

.takeout-list {
  justify-content: center;
  &--after-hero {
    margin-top: 15px;
  }

  @include md {
    padding-right: $dish-margin-size;
    flex-basis: auto;
    flex: 1;
    &--after-hero {
      margin-top: var(--big-control-size);
    }
    max-width: 100%;
  }

  @include lg {
    flex-basis: 75%;
    max-width: calc(100% - #{$sidebar-min-width});
  }
}

$sidebar-title-size: var(--big-control-size);
.takeout-sidebar {
  background: $secondary;
  border-radius: var(--sidebar-border-radius);
  position: sticky;
  top: 70px;
  left: auto;
  right: 0;
  z-index: 3;
  margin: 0;
  min-width: $sidebar-min-width;
  transition: top .2s ease;

  @include md {
    top: 90px;
    transition: top .2s ease, transform 0.2s;
  }

  @include not-mobile-but-low-height {
    overflow-y: scroll;
    max-height: calc(100vh - #{$navbar-height-md} - 15px);
  }

  &--integrated {
    @include md {
      top: 10px;
    }
    @include not-mobile-but-low-height {
      max-height: calc(100vh - 20px - 15px);
    }
  }

  .sidebar__title {
    background: var(--primary-transparent);
    border-radius: var(--sidebar-border-radius) var(--sidebar-border-radius) 0px 0px;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: bold;
    height: $sidebar-title-size;
    text-transform: uppercase;
    svg {
      margin-left: 5px;
    }
  }
}

.takeout-category {
  @extend .d-flex;
  @extend .flex-row;
  @extend .flex-wrap;

  @include md {
    justify-content: space-between;
  }
}

.takeout-category__title {
  @extend .col-12;
  font-size: 1.25rem;
  padding-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  @include title-with-after(-12px);
}

.body-black {
  .takeout-category__title, .takeout-category__text {
    color: var(--secondary);
  }
}

.takeout-category__text {
  border: 2px solid var(--primary);
  border-radius: 5px;
  display: inline-block;
  font-weight: bold;
  font-size: 1.1rem;
  @extend .mb-2;
  max-width: 750px;
  padding: 10px;
  text-align: center;
  transform: translateY(-25px);
}

.takeout-modal {
  @extend .modal-sm-fullscreen;
  @include md {
    max-width: 700px;
  }
}

.bb-1 {
  border-bottom: 1px solid var(--primary);
}

.bb-grey-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.text-xs {
  font-size: 70%;
}

.cart-discount-label {
  font-size: .85rem;
  @extend .text-success;
}
