.cookie-notice-banner {
  color: $secondary;
  background-color: $primary;
  padding: 10px;
  padding-left: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-image: url(/storage/img/cookie.svg);
  background-size: 60px;
  background-position: 10px center;
  background-repeat: no-repeat;
  @include md {
    background-size: 70px;
    background-position: 20px center;
    padding: 20px;
    padding-left: 120px;
    padding-right: 100px;
  }
  @include xl {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  a {
    color: white;
    text-decoration: underline;
  }
  button {
    background: none;
    border: 0;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 5px 12px 0 0;
    height: 100%;
    width: 50px;
    @include md {
      align-items: center;
      justify-content: center;
      font-size: 25px;
      padding-right: 5px;
      width: 100px
    }
  }
}