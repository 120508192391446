.restaurant-list {
  border-radius: 4px;
  @extend .col-12;
  @extend .col-md-8;
  @extend .col-lg-9;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10px;
  padding: 0 10px;
  @include md {
    margin-bottom: 20px;
    padding: 0 0 0 20px;
  }
  &--no-sidebar {
    @extend .col-md-12;
    @extend .col-lg-12;
    flex-flow: row wrap;
    justify-content: space-evenly;
    @include md {
      padding: 0;
    }
  }

  &__address-box {
    @extend .card;
    padding: 15px 25px;
    margin-bottom: 1rem;
  }

  &__selected-address {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.reserve-modal {
  @extend .modal-sm-fullscreen;
  $first-part-side-margin: 20px;
  &__part {
    @extend .col-12;
    @extend .col-md-6;
    &--details {
      border-bottom: 1px solid var(--primary);
      @extend .px-0;
      @extend .pb-3;
      @extend .px-md-2;
      @extend .pb-md-1;
      margin: 0 auto;
      flex-basis: calc(100% - #{2 * $first-part-side-margin});
      @include md {
        border-bottom: 0;
        border-right: 1px solid var(--primary);
        margin: 0;
      }
    }
    &--inputs {
      @extend .pt-3;
      @extend .pt-md-1;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
  &__details {
    display: inline-block;
    @extend .pl-md-2;
    div {
      margin-bottom: 5px;
    }
    svg {
      color: var(--primary);
      margin-right: 5px;
      width: 18px;
    }
  }
  &__details-date-label {
    color: var(--primary);
  }
  input[type=text], input[type=tel], input[type=email] {
    @extend .form-control;
    @extend .mt-2;
  }
  input[type=text]:first-of-type {
    @extend .mt-0;
  }
  .invalid-feedback {
    margin-bottom: 0.75rem;
  }
  .custom-checkbox {
    @extend .mb-3;
  }
  .custom-control-label {
    &::after, &::before {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__disclaimer {
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 5px;
    color: green;
    margin-top: 0.75rem;
  }
  .modal-body {
    .alert {
      width: calc(100% - 30px);
      margin-left: 15px;
    }
  }
  .modal-footer {
    border-top: 0;
    flex-flow: row wrap;
    padding-top: 0;
    justify-content: center;
  }
}
