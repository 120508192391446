// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;


// Small devices
@mixin until-sm {
  @media (max-width: #{$screen-sm-min - 1px}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin until-md {
  @media (max-width: #{$screen-md-min - 1px}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin not-mobile-but-low-height {
  @media screen and (max-height: 500px) and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin card() {
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .14);
}

@mixin child-that-abandons-parent-padding($padding: 10px) {
  position: relative;
  left: -$padding;
  top: -$padding;
  width: calc(100% + #{2 * $padding});
  margin-bottom: $padding;
}

@mixin reset-select() {
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@mixin subtitle-font-size() {
  font-size: 1.1rem;
  line-height: 1.3;
  @include md {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}

@mixin img-size($size) {
  width: $size;
  height: $size;
}

@mixin limit-lines($lines: 2, $size: 1rem) {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: $size * $lines;
  line-height: $size;
}

@mixin strikethrough($color: black, $angle: -5deg) {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid $color;
    border-color: inherit;
    transform: rotate($angle);
  }
}

@mixin title-with-after($bottom: -24px) {
  position: relative;
  &:after {
    $after-width: 64px;
    content: '';
    display: block;
    border-top: 3px solid var(--primary);
    height: 0px;
    width: $after-width;
    position: absolute;
    bottom: $bottom;
    left: calc(50% - #{$after-width}/2);
  }
}

.btn-custom-outlined {
  background-color: var(--secondary);
  border-color: var(--primary);
  color: var(--primary);
  &:hover, &:focus {
  background-color: var(--primary);
  border-color: var(--secondary);
  color: var(--secondary);
  }
  &:disabled {
    border-color:var(--disabledPrimary);
    color: var(--disabledPrimary);
    &:hover {
      color: var(--secondary);
    }
  }
}
