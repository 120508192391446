.alert-bar {
  @extend .container;
  margin: 0;
  padding: 10px;
  width: 100%;
  &:empty {
    display: inline-block;
    padding: 0;
  }
  @include sm {
    margin: 0 auto 10px;
    padding: 0;
  }
  @include md {
    margin: 0 auto 20px;
  }
  .alert {
    @extend .mb-1;
  }
}