.sidebar {
  @include card();
  @extend .col-md-4;
  @extend .col-lg-3;
  padding: 0;
  margin: 0 10px 10px;
  @include md {
    margin: 0;
  }

  &__title {
    background-color: $primary;
    border-radius: 4px 4px 0 0;
    color: $secondary;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 8px 12px;
    position: relative;
    @include md {
      padding: 15px;
    }
    .fa-chevron-up {
      transition: transform 0.5s;
    }
    &.collapsed {
      border-radius: 4px;
      .fa-chevron-up {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    padding: 10px;
    @include md {
      padding: 15px;
    }
  }

  &__label {
    border-bottom: 2px solid $primary;
    margin-top: 24px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  &__cuisine-type-icon {
    max-height: 20px;
    min-height: 10px;
    max-width: 20px;
    margin-right: 3px;
  }

  .oto-hashtag__content {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
}
