
#normal-countdown {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

#normal-countdown .time-sec {
    position: relative;
    margin: 10px;
    height: 90px;
    width: 90px;
    border-radius: 100px;
    background: #fff;
    color: #333;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

#normal-countdown .time-sec .main-time {
    color: $primary;
    font-weight: 500;
    line-height: 1;
    font-size: 2em;
}

#normal-countdown .time-sec span {
    font-size: .9em;
    font-weight: 600;
}

.reservation-data-overlay {
    background: rgba(255,255,255,0.75);
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 20px;
    padding: 20px;
}

.reservation-details {
    @include subtitle-font-size();
    div {
        margin-bottom: 5px;
    }
}