@import '../mixins';

.oto-footer {
  border-top: 1px solid $grey;
  color: #323232;
  padding: 32px 15px;

  @include md {
    padding: 39px 30px;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    @include md {
      flex-flow: row nowrap;
    }
  }

  nav {
    display: flex;
    flex-flow: column;
    align-items: center;

    @include md {
      flex-flow: row nowrap;
    }
  }

  a {
    margin: 0 0 18px;

    @include md {
      margin: 0 0 0 24px;
    }
  }

  &__social {
    svg {
      height: 24px;
    }
    a:hover, a:focus {
      text-decoration: none;
    }
  }
}
