.select-bar-form {
  @extend .col-12;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include md {
    flex-flow: row wrap;
  }
  @include lg {
    flex-flow: row nowrap;
  }
}

.select-bar {
  @extend .container;
  @extend .form-inline;

  background: var(--primary);
  border-radius: 4px;
  justify-content: center;
  gap: 0.5rem;
  padding: 10px;
  margin: 0 10px 10px;
  width: calc(100% - 20px);

  @include sm {
    margin: 0 auto 10px;
  }
  @include md {
    padding: 15px;
    margin: 0 auto 20px;
  }

  &--inline {
    background: transparent;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    @include md {
      flex-flow: row wrap;
      margin-bottom: 4px;
    }
    .select-bar__title {
      display: none;
    }
    .input-group {
      padding-top: 25px !important;
      margin-right: 0 !important;
      min-width: 100px;
      max-width: 238px;
    }
    label {
      position: absolute;
      top: 0;
      left: 24px;
      white-space: nowrap;
    }
    .form-control,
    .custom-select {
      height: 40px;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
    .input-group-prepend {
      position: absolute;
      top: -1px;
      left: 2px;
    }
    .input-group-text {
      background-color: transparent;
      border: none;
      color: black;
      width: auto !important;
      height: auto;
      svg {
        width: 16px;
      }
    }
    .date-input {
      min-width: 208px;
    }
  }

  .input-group {
    width: 100%;

    @include md {
      width: auto;
    }

    &.datepicker {
      padding: 0;
    }
  }

  &__title {
    color: $secondary;
    flex-basis: 100%;
    @include md {
      display: none;
    }
  }

  .input-group-text {
    padding-left: 0;
    padding-right: 0;
    width: 45px;
    display: flex;
    justify-content: center;
  }
}

.date-input {
  @include md {
    // min-width: 245px;
  }
}

.datepicker {
  cursor: pointer;
  input[readonly] {
    background-color: white;
    cursor: pointer;
  }
  .date-input {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

.datepicker-dropdown {
  transform: translate(30px, 10px);
}

.people-amount-input {
  min-width: 170px;
  padding-right: 0;
}
