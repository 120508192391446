$banner-height: 140px;
$banner-height-sm: 110px;
$banner-height-md: 70px;

.install-banner {
  background: $primary;
  color: $secondary;
  cursor: pointer;
  display: none;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
  position: fixed;
  z-index: $z-index-navbar + 1;
  top: 0;
  left: 0;
  width: 100%;
  min-height: $banner-height;
  @include sm {
    min-height: $banner-height-sm;
  }
  @include md {
    min-height: $banner-height-md;
  }
  &--visible {
    display: flex;
  }
  &__container {
    display: flex;
    flex-flow: row nowrap;
    @include md {
      max-width: 800px;
      margin: 0 auto;
    }
  }
}
.install-banner-img {
  background: $secondary;
  border-radius: 5px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
}
.install-banner-copy {
  max-width: 850px;
}
.install-banner--visible + #mainNav {
  top: $banner-height;
  @include sm {
    top: $banner-height-sm;
  }
  @include md {
    top: $banner-height-md;
  }
}
.install-banner--visible + #mainNav + .masthead {
  padding-top: calc(7.5rem + #{$banner-height});
}

.install-banner__close-btn {
  background: none;
  border: 0;
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  align-self: flex-start;
}

.install-banner__install-btn {
  display: none;
}
