$gradient: linear-gradient(to bottom, #{fade-out($brown, .2)} 0%, #{fade-out($brown, .2)} 100%);

@mixin welcome-bg($fileName) {
  background-image: url('/storage/img/welcome-bg/#{$fileName}');
}

body {
  background-color: #fafafa;
  &.body-black {
    background-color: transparent;
  }
}

// Styling for the masthead
.masthead {
  padding-top: 7.5rem;
  padding-bottom: calc(10rem - #{$navbar-height});
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  &--with-webp {
    @include welcome-bg('bg-oto-xs.webp');
  }
  &--no-webp {
    @include welcome-bg('bg-oto-xs.jpg');
  }

  h1 {
    font-size: 2rem;

    @include md {
      font-size: 2.25rem;
    }
  }

  .subtitle {
    @extend .text-white-80;
    @extend .font-weight-light;
    @extend .mb-4;
    @extend .mb-md-5;
    @include subtitle-font-size();

    @include md {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-up(sm) {
    &--with-webp {
      @include welcome-bg('bg-oto-sm.webp');
    }
    &--no-webp {
      @include welcome-bg('bg-oto-sm.jpg');
    }
  }

  @include media-breakpoint-up(md) {
    &--with-webp {
      @include welcome-bg('bg-oto.webp');
    }
    &--no-webp {
      @include welcome-bg('bg-oto.jpg');
    }
    padding-top: 10rem;
  }

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: $navbar-height;
    padding-bottom: 100px;

    h1 {
      font-size: 3rem;
    }
  }

  &--homepage {
    display: flex;
    align-items: center;
    min-height: 425px;
    padding: $navbar-height 0 112px;

    @include md {
      padding: $navbar-height-md 0 112px;
      min-height: 500px;
    }
  }
}

$homepage-controls-border-width: 1px;
$homepage-controls-size: 220px;
$homepage-controls-md-size: 180px;
$homepage-radius: 30px;

.cta-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .flex-md-row;
  @extend .flex-nowrap;
  @extend .align-items-center;
  @extend .justify-content-center;

  .input-group {
    padding: 0;
    margin-bottom: 20px;

    @include md {
      margin-bottom: 0;
      margin-right: 20px;
      width: auto;
    }
  }
}

.cta-btn {
  @extend .btn;
  @extend .btn-primary;
  border-radius: 0.25rem;
  height: 38px;
  white-space: nowrap;
  max-width: 250px;
  width: 100%;

  @include md {
    width: 220px;
  }

  &--secondary {
    @extend .btn-secondary;
  }
}


.homepage-control {
  margin: 0 0 20px;
  width: $homepage-controls-size;

  @include md {
    max-width: 250px;
    margin: 0 20px 0 0;
    width: $homepage-controls-md-size;
  }

  input,
  .input-group-text {
    border-color: $primary;
    border-width: $homepage-controls-border-width;
    color: $primary;
    background: $secondary;
    height: 50px;
  }

  .input-group-text {
    border-right-width: $homepage-controls-border-width;
    border-top-left-radius: $homepage-radius;
    border-bottom-left-radius: $homepage-radius;
    padding: 0.5rem 1rem;
    width: 50px;
  }

  .input-group-prepend {
    height: 100%;
  }

  &--with-prepend {
    @include md {
      width: $homepage-controls-md-size + 50px;
    }
  }

  &.homepage-select {
    .input-group-text {
      border-left-width: 0;
      border-top-width: 0;
      border-bottom-width: 0;
    }
  }

  &.datepicker {
    padding: 0;
  }
}

.homepage-select {
  background-color: $secondary;
  border: $homepage-controls-border-width solid $primary;
  border-radius: $homepage-radius;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: $cta-select-height;
  padding: 0;
  z-index: 10;
  overflow: hidden;


  select {
    @include reset-select();
    background: transparent;
    border-radius: 0;
    color: $primary;
    cursor: pointer;
    font-family: $btn-font-family !important;
    font-size: $xl-font-size;
    font-weight: 700;
    padding: 0 20px;
    margin: 0;
    display: block;
    text-transform: uppercase;
    height: 100%;
    width: 100%;

    @include md {
      font-size: $xl-font-size-md;
    }
  }

  &:after {
    border-left: $homepage-controls-border-width solid $primary;
    background: $secondary;
    content: '\2228';
    color: $primary;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    line-height: 50px;
    text-align: center;
    z-index: -1;
    transform: translateX(-3px);

    @include md {
      border-left-width: 2px;
      font-size: 25px;
      line-height: 45px;
      font-weight: 700;
    }
  }
}

.datepicker-homepage {
  width: auto;

  .form-control.date-input {
    border-top-right-radius: $homepage-radius !important;
    border-bottom-right-radius: $homepage-radius !important;
  }
}

.welcome-city {
  position: relative;
  white-space: nowrap;

  &:after {
    border: 1px solid $primary;
    display: block;
    content: '';
    position: absolute;
    top: calc(100% - 5px);
    left: 0;
    width: 100%;
  }
}

$home-banner-primary: $primary;
$home-banner-secondary: $secondary;

.home-install-banner-wrapper {
  overflow: hidden;

  @include md {
    margin-top: -100px;
  }

  .install-banner {
    background: $home-banner-secondary;
    position: relative;
    z-index: 2;

    &-img {
      border-radius: 12px;
      @include img-size(90px);

      @include md {
        @include img-size(70px);
      }
    }
  }

  .install-banner__container {
    background: $home-banner-primary;
    border-radius: 10px;
    color: $home-banner-secondary;
    padding: 30px 25px;
    margin-bottom: 50px;

    @include md {
      padding: 40px;
      max-width: 600px;
    }
  }

  .install-banner__close-btn {
    display: none;
  }

  .install-banner__install-btn {
    @extend .btn;
    @extend .btn-secondary;
    display: block;
    margin-top: 15px;
  }
}

.newsletter {
  &__form {
    @extend .col-12;
    @extend .col-sm-10;
    @extend .col-md-9;
    @extend .col-lg-6;
    @extend .mx-auto;
    @extend .text-center;
    @extend .mb-5;
    @extend .mb-sm-0;
    display: flex;
    flex-flow: column;

    @include md {
      flex-flow: row nowrap;
    }
  }

  &__input {
    @extend .form-control;
    @extend .mb-3;
    @extend .mb-md-0;
    border-radius: 0;

    @include md {
      flex: 1;
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn-primary;
    border-radius: 0;
    width: 100%;

    @include md {
      width: auto;
    }
  }
}

/* NEW LANDING */

body.font-lato {
  font-family: $lato;
  font-size: 18px;
}

.home-faq {
  padding: 0 15px;
  margin-top: 100px;
}

.home-title {
  color: $sort-of-black;
  font-family: $font-family-serif;
  font-size: 3rem;
  margin: 0 0 1rem;
  line-height: 125%;
  text-align: center;

  &--smaller {
    font-size: 2.5rem;
  }
}

.serif {
  font-family: var(--font-family-serif);
}

.home-title-main {
  color: var(--secondary);
  font-family: $font-family-serif;
  font-size: 3rem;
  line-height: 1.25;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 900px;
}

.home-city {
  color: var(--secondary);
  font-family: $font-family-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.25;
  text-align: center;
  text-decoration: underline;
  width: 100%;

  @include md {
    font-size: 4rem;
  }
}

.home-subtitle {
  color: #323232;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  line-height: 175%;
  text-align: center;
}

.home-small-title {
  color: #9E9E9E;
  font-family: $font-family-sans-serif;
  font-size: 0.675rem;
  line-height: 1.5;
  margin-bottom: 8px;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.home-steps-container {
  @include md {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1050px;
  }
}

$primary-dimmed: rgba(244, 98, 58, 0.1);
$home-step-delimeter-size: 20px;

.home-step {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
  min-height: 150px;

  @include md {
    flex-flow: row nowrap;
    margin: 0 20px 0;
  }

  &:after,
  &:before {
    border-radius: 50%;
    background: $primary-dimmed;
    content: '';
    width: $home-step-delimeter-size;
    height: $home-step-delimeter-size;
    position: absolute;
    left: 50%;
  }

  &:after {
    bottom: -10px;
    transform: translate(-50%, -10px);

    @include md {
      position: static;
      transform: translateX(15px);
    }
  }

  &:before {
    position: absolute;
    top: -10px;
    transform: translate(-50%, 0);

    @include md {
      position: static;
      transform: translateX(-5px);
    }
  }

  &:first-child:before {
    display: none;
  }

  &:last-child:after {
    display: none;
  }

  &__number {
    color: $primary-dimmed;
    font-family: $font-family-serif;
    font-weight: bold;
    font-size: 10rem;
    line-height: 125%;
    text-align: center;
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:nth-child(3) {
    .home-step__number {
      font-size: 8.25rem;
    }
  }

  &:nth-child(4) {
    .home-step__number {
      font-size: 8rem;
    }
  }

  &__text {
    white-space: nowrap;
    text-align: center;

    div {
      font-size: 1.25rem;
      line-height: 1.25;
    }
  }
}

.home-fr {
  background-size: cover;
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin: 6rem auto 4rem;
  max-width: 920px;
  padding: 64px 30px;
  width: calc(100% - 40px);

  &.with-webp {
    background-image: url('/images/bg-restaurant.webp');
  }
  &.no-webp {
  background-image: url('/images/bg-restaurant.jpg');
  }

  @include md {
    padding: 96px 94px;
  }

  .home-title,
  .home-small-title,
  p,
  & {
    color: var(--secondary);
    text-align: left;
  }

  &__link {
    border: 2px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--secondary);
    font-weight: 900;
    display: inline-block;
    padding: 10px 16px;
    text-transform: uppercase;

    svg {
      color: inherit;
      margin-right: 10px;
    }

    &:hover {
      text-decoration: none;

      path {
        fill: $primary;
      }
    }
  }
}

.home-partners {
  padding: 0 15px;

  .partner-logos {
    @include md {
      img {
        filter: grayscale(50%);
        margin-bottom: 0;

        &:hover {
          filter: none;
        }
      }
    }
  }
}

.home-contact {
  padding: 0 30px;
  margin: 100px 0;

  @include md {
    margin-bottom: 160px;
  }

  &__wrapper {
    @extend .container;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    @include md {
      flex-flow: row nowrap;
    }
  }

  &__part {
    color: var(--primary);
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 2rem 2rem;

    @include md {
      margin: 0 2rem;
    }
  }

  &__icon-wrapper {
    background: var(--primary-transparent);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0.5rem;
    width: 65px;
    height: 65px;
  }

  svg {
    height: 28px;
  }
}

@mixin new-navbar() {
@include until-sm {
  .navbar-collapse.show {
    height: calc(100vh - 66px);
  }
  .navbar-collapse.show, .navbar-collapse.collapsing {
    ul {
      margin-top: 150px !important;
      text-align: center;

    }
  }
  .nav-item {
    a {
      font-size: 2rem;
    }
    &--outlined {
      background: var(--primary);
      width: calc(100% - 30px);
      margin-left: 15px;
      a {
        color: var(--secondary);
      }
    }
    &--pinned {
      margin-top: calc(100vh - 442px);
      margin-bottom: 30px;
    }
  }
}
}
