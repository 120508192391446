// Base styling for the navbar
#mainNav {
  @extend .fixed-top;
  @extend .py-2;
  @extend .py-md-3;
  box-shadow: $box-shadow;
  background: var(--secondary);
  transition: background-color 0.2s ease;
  .navbar-brand {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    color: $gray-900;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $gray-600;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        font-size: 0.9rem;
        padding: 0.75rem 0;
        text-transform: uppercase;
        &:hover,
        &:active {
          color: $navbar-link-color;
        }
        &.active {
          color: $navbar-link-color !important;
        }
      }
    }
    .dropdown-menu {
      .nav-link {
        padding: 0.5rem;
      }
    }
  }
  .navbar-brand-svg {
    fill: $navbar-link-color;
    @include lg {
      fill: $secondary;
    }
  }
  &.navbar-transparent {
    background: transparent;
    .navbar-brand-svg {
      fill: $navbar-link-color;
    }
    .navbar-nav .nav-item .nav-link {
      color: $sort-of-black;
      &:hover {
        color: $navbar-link-color;
      }
    }
  }
  &.navbar-scrolled {
    .navbar-brand-svg {
      fill: $navbar-link-color;
    }
  }
  &.navbar-transparent.navbar-scrolled {
    background: var(--secondary);
  }
  &.navbar-transparent {
    @include until-md {
      background: var(--secondary);
    }
  }
  &.bg-primary {
    .navbar-brand-svg {
      fill: $secondary;
    }
  }
  @include media-breakpoint-up(lg) {
    // Base styling for the navbar - screen sizes greater than the large breakpoint
    box-shadow: none;
    background-color: transparent;
    .navbar-brand {
      color: fade-out($white, 0.3);
      &:hover {
        color: var(--secondary);
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: fade-out($white, 0.3);
          padding: 0;
          margin: 0 1rem;
          position: relative;
          &:not(.nav-link--outlined):not(.dropdown-toggle) {
            &:hover:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              border-bottom: 2px solid $navbar-link-color;
              width: 100%;
            }
          }
          &.nav-link__dropdown {
            text-align: center;
            &:not(:last-of-type) {
              margin-bottom: 0.5rem;
            }
          }
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
        }
      }
    }
    // Navbar styling applied when the page is scrolled
    &.navbar-scrolled {
      box-shadow: $box-shadow;
      background: var(--secondary);
      .navbar-brand {
        color: $gray-900;
        &:hover {
          color: $navbar-link-color;
        }
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $gray-900;
            &:hover {
              color: $navbar-link-color;
            }
          }
        }
      }
    }
  }

  .dropdown-menu .nav-link {
    color: $navbar-link-color !important;
    border: none;
  }
}

.nav-item--outlined {
  position: relative;
  padding-left: 0 !important;
  @include md {
    margin-left: 1rem;
    &:not(.dropdown)::after {
      border: 2px solid $secondary;
      position: absolute;
      left: -5px;
      top: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      content: "";
      border-radius: 5px;
    }
    &.dropdown {
      height: 21px;
      transform: translate(-5px, -5px);
    }
  }
  .nav-link {
    margin: 0 !important;
    border: 2px solid $secondary;
    border-radius: 5px;
    display: inline-block;
    padding: 3px 5px !important;
  }

  .dropdown-menu {
    top: calc(100% + 10px);
    @include until-md {
      border: none;
    }
    @include md {
      text-align: center;
    }
  }
}

.bg-primary {
  .nav-item--outlined::after {
    border-color: $secondary;
  }
}

.navbar-light,
.navbar-transparent {
  .nav-item--outlined {
    color: $navbar-link-color !important;
    .nav-link {
      color: $navbar-link-color !important;
    }
    &::after,
    .nav-link {
      border-color: $navbar-link-color;
    }
  }
}
