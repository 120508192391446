$fr-btn-size: 56px;
$fr-btn-radius: 10px;

h2.fr-title {
  font-size: 2rem;
  @include md {
    font-size: 2.5rem;
  }
}

h3.fr-subtitle {
  font-size: 1.5rem;
}

.fr-title, .fr-subtitle {
  font-weight: bold;
}

.fr-desc {
  color: #686868;
  margin: 0 auto;
  max-width: 350px;
}

.for-restaurants-intro {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(244, 98, 58, 0.1) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(/storage/img/for-restaurants/bg.png);
  background-image: url(/storage/img/for-restaurants/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 50px + $navbar-height 20px 50px;
  min-height: 100vh;
  @include md {
    padding: 182px 20px 100px;
  }

  .fr-title {
    @include title-with-after();
    font-size: 3rem;
    text-align: center;
    @include md {
      font-size: 4rem;
    }
    margin-bottom: 40px;
  }

  .fr-subtitle {
    text-align: center;
    margin-bottom: 48px;
  }
}

$fr-btn-border-width: 2px;
.for-restaurants-cta-btn {
  @extend .cta-btn;
  border-radius: $fr-btn-radius;
  border-width: $fr-btn-border-width;
  height: $fr-btn-size;
  line-height: 20px; // height - padding x 2 - border x 2
  text-transform: uppercase;
  padding: ($fr-btn-size - 24px) / 2 10px;
  min-width: 255px;
  width: auto;
  max-width: none;
  transition: all 0.3s;
  &:hover {
    background-color: transparent;
    color: var(--primary);
  }
  &.cta-btn--secondary:hover {
    background-color: transparent;
    border-color: var(--secondary);
    color: var(--secondary);
  }
}

@mixin for-restaurants-section($subtitle-color: $primary, $icon-color: black) {
  padding: 50px 15px;
  @extend .container;
  text-align: center;

  @include md {
    padding: 96px 15px;
  }

  .fr-title {
    @include title-with-after();
    margin-bottom: 5rem;
    @include md {
      margin-bottom: 88px;
    }
  }
  .fr-subtitle {
    color: $subtitle-color;
    max-width: 350px;
    margin: 0 auto 1rem;
  }

  .iconify {
    color: $icon-color;
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
}

@mixin cta-section($bg) {
  padding: 36px 20px;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $bg;
    opacity: 0.75;
  }
  @include md {
    padding: 64px 20px;
  }
  .fr-title {
    color: $secondary;
  }
  .fr-subtitle {
    color: $secondary;
    opacity: 0.75;
  }
}

@mixin section-item() {
  @extend .col-12;
  @extend .col-md-4;
  margin-bottom: 4.5rem;
}

.for-restaurants-why {
  @include for-restaurants-section(black, $primary);
}

.why-section-item {
  @include section-item();
}

.cta-try-now {
  @include cta-section(url(/storage/img/for-restaurants/cta-bg1.png));
  background-color: $primary;
  .fr-title {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 48px;
    }
  }
  .small {
    font-size: 0.5em;
    transform: translateY(-80%);
    display: inline-block;
  }
}

.cta-section__content {
  position: relative;
  z-index: 3;
}

.cta-call-me {
  @include cta-section(url(/storage/img/for-restaurants/cta-bg2.png));
  background-color: $sort-of-black;
  padding-top: $navbar-height !important;
  @include md {
    padding-top: 82px !important;
  }
  .fr-subtitle {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 48px;
    }
  }
  label {
    color: $secondary;
  }
}

.for-restaurants-how {
  @include for-restaurants-section(black, $primary);
}

.how-section-item {
  @include section-item();
}

.fr-inputs-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-bottom: 30px;
  @include md {
    flex-flow: row wrap;
    margin-bottom: 20px;
  }
}

.fr-input {
  background: transparent;
  border: 1px solid $secondary;
  border-radius: $fr-btn-radius;
  color: $secondary;
  font-size: 1rem;
  padding: 15px 10px;
  height: $fr-btn-size;
  margin-bottom: 20px;
  @include md {
    margin-left: 15px;
    margin-right: 15px;
    min-width: 250px;
  }
}

#fr-submit {
  width: 100%;
  @include md {
    width: auto;
  }
}

.fr-checkbox-wrapper {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  text-align: left;
  max-width: 800px;
  input {
    transform: translateY(5px);
  }
  label {
    text-align: left;
    margin-left: 15px;
  }
}

.cta-your-time {
  @include cta-section(transparent);
  padding-top: 0 !important;
  .fr-title {
    color: $primary;
    margin-bottom: 2.5rem;
  }
}

.partners {
  @include for-restaurants-section();
}

.partner-logos {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  img {
    object-fit: contain;
    object-position: center;
    height: 120px;
    margin: 0 10px 20px;
    max-width: 100%;
  }
}

.fr-note {
  @extend .bg-light;
  padding: 2rem 1rem 1em;
}

.nowrap {
  white-space: nowrap;
}
